.active > g > path,
.active > path {
  fill: var(--magenta);
}

.tooltip {
  background-color: var(--white) !important;
  border: 1px solid var(--light-grey) !important;
  border-radius: 8px !important;
  height: 26px !important;
  padding: 8px !important;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
