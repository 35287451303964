.inputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.label {
  font-family: 'Nexa';
  font-weight: bold;
  font-size: 1.6rem;
  margin: 0 5px;
  padding-top: 0.3rem;
  cursor: pointer;
  width: 10rem;
  text-align: center;
}
.leftArrowBtn,
.rightArrowBtn {
  background: transparent;
  border: none;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
.leftArrowBtn {
  text-align: left;
}
.leftArrowBtn path {
  fill: var(--darkest-grey);
}
.leftArrowBtn:disabled path {
  fill: var(--grey);
}
.rightArrowBtn {
  text-align: right;
}
.rightArrowBtn path {
  fill: var(--darkest-grey);
}
.rightArrowBtn:disabled path {
  fill: var(--grey);
}
