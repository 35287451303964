@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .scrollbar::-webkit-scrollbar-button {
    height: 5px;
  }

  .scrollbar::-webkit-scrollbar-track {
    background: transparent;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: var(--light-mid-grey);
    border-radius: 5px;
  }

  .toast {
    @apply !border-none !shadow-none text-14 !p-0 leading-[2.1rem] w-[45rem] !max-w-[45rem] text-left all-child:justify-start;
  }

  .toast > * {
    margin: 0 !important;
  }

  .words-break {
    word-break: break-word;
  }

  .tooltip {
    background-color: var(--white) !important;
    border: 1px solid var(--light-grey) !important;
    border-radius: 8px !important;
    height: 26px !important;
    padding: 8px !important;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .truncateOneLine {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .activeIcon path {
    fill: #B01BAE
  }

  .disableIcon path {
    fill: #c2c1c1
  }
}

@layer base {
  [type='checkbox'],
  select {
    @apply text-magenta !ring-offset-0 !ring-transparent rounded-md;
  }
  [type='checkbox']:disabled,
  [type='checkbox']:disabled:hover {
    @apply bg-[#f8f8f8] border-[#d1d1d1];
  }
  [type='checkbox']:disabled:checked {
    @apply bg-[#d1d1d1] text-[#ededed];
  }
  [type='text']:focus,
  [type='password']:focus {
    @apply !ring-offset-0 !ring-transparent;
  }
  [type='radio']:checked {
    background-image: url('../src/assets/icons/radio-on-active.svg');
    @apply !bg-transparent;
  }
  [type='radio']:focus,
  [type='radio']:hover {
    @apply !ring-offset-0 !ring-transparent !bg-transparent;
  }
}

@font-face {
  font-family: 'Nexa';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/Nexa/Nexa-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Nexa';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/Nexa/Nexa-Light.woff') format('woff');
}

html {
  /* 10px but respect user font-size setting on browser */
  font-size: 10px;
  color: var(--darkest-grey);
}

* {
  font-family: 'Nexa';
}

:root {
  --gradient: linear-gradient(206.28deg, #b01bae 0%, #da3f85 100%);
  --gradient-lighter: linear-gradient(206.28deg, #e11edf 0%, #d93a7d 100%);
  --gradient-darker: linear-gradient(206.28deg, #871286 0%, #c52668 100%);
  --red-01: #f5222d;
  --secondary-red: #d31759;
  --magenta: #b01bae;
  --magenta-lighter: #bf48be;
  --magenta-darker: #8c158b;
  --grey: #c2c1c1;
  --light-grey: #e8e8e8;
  --lighter-grey: #f5f0f5;
  --lighest-grey: #f9f9f9;
  --white: #ffffff;
  --light-mid-grey: #d9d9d9;
  --darkest-grey: #373737;
  --grey-01: #a9a9a9;
  --shadow-primary: 0px 0px 5px 2px rgba(220, 220, 220, 0.5);
  --shadow-input-active: 0px 0px 5px 2px rgb(176 27 174 / 20%);
  --shadow-elevation-07: 0px 0px 5px 2px rgba(220, 220, 220, 0.5);
}

.shadow-tooltip.place-bottom::before {
  border-bottom-color: #E8E8E8 !important;
}
  
.shadow-tooltip.place-bottom::after {
  border-bottom-color: #F9F9F9 !important;
}
  
.shadow-tooltip.place-right::before {
  border-right-color: #E8E8E8 !important;
}
  
.shadow-tooltip.place-right::after {
  border-right-color: #F9F9F9 !important;
}