.container {
  display: flex;
  align-items: center;
}
.btn {
  cursor: pointer;
  width: 1.6rem;
  height: 1.6rem;
}
.btn.disabled {
  cursor: not-allowed;
}
.btn.disabled > path {
  fill: #c2c1c1;
}

.content {
  font-weight: 700;
  font-size: 1.6rem;
  text-align: center;
  margin: 0 5px;
  padding-top: 0.3rem;
  cursor: pointer;
  width: 15rem;
}
