:global #schedule-calendar .fc-daygrid-day-number {
  color: var(--darkest-grey);
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.8rem;
  width: 100%;
  padding: 0;
}

:global #schedule-calendar .fc-daygrid-day.fc-day-today {
  background-color: var(--white);
}

:global #schedule-calendar .fc-daygrid-day-events {
  display: none;
}

:global #schedule-calendar .fc-daygrid-day-frame {
  display: flex;
}

:global #schedule-calendar .fc-daygrid-day-top {
  width: 100%;
  display: flex;
  opacity: 1;
}

:global #schedule-calendar .fc-day-other {
  opacity: 1;
}

:global #schedule-calendar td {
  border-color: var(--light-grey);
}

:global #schedule-calendar th {
  border-color: var(--light-grey);
}

:global #schedule-calendar table[role='presentation'].fc-col-header {
  min-width: 100%;
  width: 100% !important;
}

:global #schedule-calendar table[role='presentation'].fc-scrollgrid-sync-table {
  min-width: 100%;
  width: 100% !important;
}

:global #schedule-calendar div.fc-daygrid-body.fc-daygrid-body-unbalanced {
  min-width: 100%;
  width: 100% !important;
}
