:global #date-range-picker {
  --hover: #f7edf7;
}

:global #date-range-picker .mantine-CalendarHeader-calendarHeaderControl > svg {
  width: 2rem;
  height: 2rem;
}

:global #date-range-picker .mantine-DatePickerInput-calendarHeaderLevel {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.7rem;
  color: var(--darkest-grey);
  font-family: 'Nexa';
  pointer-events: none;
}

:global #date-range-picker .mantine-Day-day {
  font-family: 'Nexa';
  font-size: 1.4rem;
  line-height: 2.1rem;
}

:global #date-range-picker .mantine-DatePickerInput-weekday {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2.1rem;
  color: var(--darkest-grey);
}

:global
  #date-range-picker
  .mantine-Day-day:not([disabled]):not([data-selected='true']):hover {
  background: var(--hover);
}

:global #date-range-picker .mantine-Day-day[data-weekend='true'],
:global #date-range-picker .mantine-Day-day:not([disabled]) {
  color: var(--darkest-grey);
}

:global #date-range-picker .mantine-Day-day[data-selected='true'] {
  border-radius: 50%;
  color: var(--white);
  background: var(--gradient);
}

:global
  #date-range-picker
  .mantine-Day-day[data-in-range='true']:not(
    .mantine-Day-day[data-selected='true']
  ) {
  background: var(--hover);
}

:global #date-range-picker .mantine-DatePickerInput-input {
  display: none;
}
