.truncateName {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncateSpecialty {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.isNotDebugMode {
  @apply bg-white text-darkest-grey shadow-elevation-07 rounded-[4px] pl-1;
}

.officeClosedHour {
  @apply bg-darkBlack text-white pl-1;
}

.officeHoliday {
  @apply bg-darkBlack text-white pl-1;
}

.event {
  @apply bg-darkBlack text-white pl-1;
}

.pmsPractitionerHour {
  @apply bg-darkBlack text-white pl-1;
}

.availableEvent {
  @apply bg-white text-darkest-grey border border-dashed;
}

.blocked {
  @apply bg-darkBlack text-white pl-1;
}

.tooltip {
  background-color: var(--lighest-grey) !important;
  border: 1px solid var(--light-grey) !important;
  border-radius: 8px !important;
  padding: 0 !important;
  width: 235px !important;
  height: 238px !important;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1 !important;
}
