.btn {
  @apply rounded-full cursor-pointer font-bold flex justify-center items-center text-14 leading-[2.1rem] min-w-[15rem] min-h-[4rem];
}

.primary {
  @apply text-white;
  background: var(--gradient);
  border-width: 1px;
}
.primary:hover {
  background: var(--gradient-lighter);
}
.primary:active {
  background: var(--gradient-darker);
}
.primary:disabled {
  background: var(--grey);
}

.secondary {
  @apply bg-white text-magenta;
  border: 1px solid var(--magenta);
}

.secondary:hover {
  @apply text-magenta-lighter;
  border: 1px solid var(--magenta-lighter);
}

.secondary:active {
  @apply text-magenta-darker;
  border: 1px solid var(--magenta-darker);
}

.secondary:disabled {
  @apply text-grey;
  border: 1px solid var(--grey);
}
