.headerWrapper {
  font-family: 'Nexa';
  font-weight: bold;
  font-size: 16px;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  color: #737373;
}
.headerNavigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.leftArrowIcon,
.rightArrowIcon {
  background: transparent;
  border: none;
  width: 2.4rem;
  height: 2.4rem;
  margin-left: 2px;
  cursor: pointer;
}
.leftArrowIcon path {
  fill: #373737;
}
.leftArrowIcon:disabled path {
  fill: #c2c1c1;
}
.rightArrowIcon path {
  fill: #373737;
}
