.tooltip {
  background-color: var(--white) !important;
  border: 1px solid var(--light-grey) !important;
  border-radius: 8px !important;
  height: auto;
  max-height: 50px !important;
  line-height: 1.5;
  padding: 8px !important;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}