:global .day-selector-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-family: 'Nexa';
}
:global .day-selector-container .react-datepicker-wrapper {
  flex: 1;
}
:global .day-selector-container .react-datepicker-popper {
  z-index: 3;
}
@media (max-width: 500px) {
  :global .day-selector-container .react-datepicker-popper {
    transform: translate3d(-50%, 186px, 0px) !important;
    left: 50% !important;
    margin-left: 0 !important;
    width: calc(100% - 4.8rem);
  }
}
:global
  .day-selector-container
  .react-datepicker-popper[data-placement^='bottom'] {
  z-index: 100;
}
:global .day-selector-container .react-datepicker {
  padding: 1.5rem 1.2rem;
  border: none;
  border-radius: 0.8rem;
  box-shadow: 0px 1px 3px 3px rgba(168, 168, 168, 0.25);
  display: flex;
  justify-content: center;
}
:global .day-selector-container .react-datepicker__triangle {
  display: none;
}
:global .day-selector-container .react-datepicker__navigation {
  top: 2.7rem;
}
:global .day-selector-container .react-datepicker__navigation-icon::before {
  border: none;
}
:global .day-selector-container .react-datepicker__navigation--next {
  background: url('../../assets/icons/right.svg') no-repeat;
  width: 2rem;
  height: 2rem;
  border: none;
  right: 2rem;
}
:global .day-selector-container .react-datepicker__navigation--previous {
  background: url('../../assets/icons/left.svg') no-repeat;
  width: 2rem;
  height: 2rem;
  border: none;
  left: 3rem;
}
:global .day-selector-container .react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
}
:global
  .day-selector-container
  .react-datepicker__header
  .react-datepicker__current-month {
  font-family: 'Nexa';
  font-size: 1.8rem;
  font-style: normal;
  font-weight: bolder;
  line-height: 2.7rem;
  margin-bottom: 1.6rem;
}
:global
  .day-selector-container
  .react-datepicker__header
  .react-datepicker__day-names {
  font-family: 'Nexa';
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  color: #373737;
}
:global
  .day-selector-container
  .react-datepicker__header
  .react-datepicker__day-names
  .react-datepicker__day-name {
  width: 3rem;
  line-height: 2.1rem;
  color: #373737;
  margin: 0.5rem;
}
:global
  .day-selector-container
  .react-datepicker__month
  .react-datepicker__day--outside-month {
  visibility: hidden;
}
:global
  .day-selector-container
  .react-datepicker__month
  .react-datepicker__day {
  width: 3rem;
  height: 3rem;
  color: #373737;
  font-family: 'Nexa';
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3rem;
  margin: 0.5rem;
}
:global
  .day-selector-container
  .react-datepicker__month
  .react-datepicker__day--disabled {
  color: #c2c1c1;
}
:global
  .day-selector-container
  .react-datepicker__month
  .react-datepicker__day--selected {
  color: #fff;
  border-radius: 50%;
  background-color: #b01bae;
}
:global
  .day-selector-container
  .react-datepicker__month
  .react-datepicker__day--selected:focus-visible {
  outline: transparent;
}
:global
  .day-selector-container
  .react-datepicker__month
  .react-datepicker__day--keyboard-selected {
  background-color: transparent;
}
:global
  .react-datepicker__day.react-datepicker__day--disabled.react-datepicker__day--excluded.react-datepicker__day--selected {
  background-color: transparent;
  color: var(--grey);
}
