:global #admin-appt-calendar {
  --hover: #f7edf7;
}

:global #admin-appt-calendar table {
  border-collapse: separate;
  border-spacing: 0 0.8rem;
}


:global #admin-appt-calendar .mantine-Month-monthRow {
  margin-bottom: 10px;
}

:global #admin-appt-calendar .mantine-CalendarHeader-calendarHeaderControl > svg {
  width: 2rem;
  height: 2rem;
}

:global #admin-appt-calendar .mantine-DatePickerInput-calendarHeaderLevel {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.7rem;
  color: var(--darkest-grey);
  font-family: 'Nexa';
  pointer-events: none;
}

:global #admin-appt-calendar .mantine-Day-day {
  font-family: 'Nexa';
  font-size: 1.4rem;
  line-height: 2.1rem;
}

:global #admin-appt-calendar .mantine-DatePickerInput-weekday {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2.1rem;
  color: var(--darkest-grey);
}

:global
  #admin-appt-calendar
  .mantine-Day-day:not([disabled]):not([data-selected='true']):hover {
  background: var(--hover);
  border-radius: 50%;
}

:global #admin-appt-calendar .mantine-Day-day[data-weekend='true'],
:global #admin-appt-calendar .mantine-Day-day:not([disabled]) {
  color: var(--darkest-grey);
}

:global #admin-appt-calendar .mantine-Day-day[data-selected='true'] {
  background: var(--hover);
}

:global #admin-appt-calendar .mantine-Day-day[data-first-in-range='true'] {
  background: var(--hover);
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
}

:global #admin-appt-calendar .mantine-Day-day[data-last-in-range='true'] {
  background: var(--hover);
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
}

:global
  #admin-appt-calendar
  .mantine-Day-day[data-in-range='true']:not(
    .mantine-Day-day[data-selected='true']
  ) {
  background: var(--hover);
}

:global
  #admin-appt-calendar
  .mantine-Day-day[data-in-range='true']:not(
    .mantine-Day-day[data-selected='true']
  ):hover {
  border-radius: 0 !important;
}

:global #admin-appt-calendar .mantine-DatePickerInput-input {
  display: none;
}

:global #admin-appt-calendar tr td:first-child button {
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
}

:global #admin-appt-calendar tr td:last-child button {
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
}

:global #admin-appt-calendar button[data-previous="true"]:disabled path {
  fill: #ced4da
}

:global #admin-appt-calendar button[data-next="true"]:disabled path {
  fill: #ced4da
}

:global #admin-appt-calendar .active-day {
  border-radius: 50%;
  background: var(--gradient);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

:global #admin-appt-calendar .mantine-DatePicker-weekday {
  font-weight: bold;
  font-size: 12px; 
  color: var(--darkest-grey);
}

:glboal #admin-appt-calendar .mantine-DatePicker-calendarHeaderLevel {
  font-weight: bold;
  font-size: 14px; 
  color: var(--darkest-grey);
}