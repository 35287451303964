.icon path {
  fill: var(--grey-01);
}

.different path {
  fill: var(--magenta);
}

.tooltip {
  background-color: var(--lighest-grey) !important;
  border: 1px solid var(--light-grey) !important;
  border-radius: 8px !important;
  padding: 0 !important;
  width: 276px !important;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1 !important;
}
