:global .calendar-selector-container .react-datepicker-popper {
  z-index: 10;
}
@media (max-width: 500px) {
  :global .calendar-selector-container .react-datepicker-popper {
    transform: translate3d(-50%, 186px, 0px) !important;
    left: 50% !important;
    margin-left: 0 !important;
    width: calc(100% - 4.8rem);
  }
}
:global .calendar-selector-container .react-datepicker {
  box-sizing: border-box;
  width: 100%;
}
:global .calendar-selector-container .react-datepicker-wrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
:global
  .calendar-selector-container
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  width: 100%;
  padding: 0 0 0 0.6rem;
  font-family: 'Nexa';
  font-size: 1.6rem;
  font-weight: bold;
  border: none;
}
:global
  .calendar-selector-container
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input:hover {
  cursor: pointer;
}
:global
  .calendar-selector-container
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input:focus {
  outline: none;
}
:global
  .calendar-selector-container
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input::placeholder {
  color: #a9a9a9;
}
:global
  .calendar-selector-container
  .react-datepicker__tab-loop
  .react-datepicker {
  padding: 1.6rem 1.3rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 1px 3px 3px rgba(168, 168, 168, 0.25);
}
:global
  .calendar-selector-container
  .react-datepicker__tab-loop
  .react-datepicker__triangle {
  display: none;
}
:global
  .calendar-selector-container
  .react-datepicker__tab-loop
  .react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
}
:global
  .calendar-selector-container
  .react-datepicker__tab-loop
  .react-datepicker__month-container {
  width: 100%;
}
:global
  .calendar-selector-container
  .react-datepicker__tab-loop
  .react-datepicker__month-text {
  font-family: 'Nexa';
  width: 9.2rem;
  height: 3.2rem;
  line-height: 3.2rem;
  font-size: 1.6rem;
  font-weight: 400;
}
:global
  .calendar-selector-container
  .react-datepicker__tab-loop
  .react-datepicker__month-text--keyboard-selected {
  border-radius: 5rem;
  background-color: #b01bae;
}
:global .react-datepicker__month--disabled {
  background-color: initial !important;
  color: #ccc !important;
}