:global #dashboard-calendar .calendar-header {
  font-family: 'Nexa';
  font-size: 1.4rem;
  color: var(--darkest-grey);
  font-weight: normal;
}

:global #dashboard-calendar table.fc-col-header {
  width: 100% !important;
}

:global
  #dashboard-calendar
  .fc-scrollgrid-section.fc-scrollgrid-section-header
  .fc-scroller {
  overflow: hidden !important;
}

:global #dashboard-calendar .fc-scroller-harness.fc-scroller-harness-liquid {
  margin-right: -1.8rem;
}

:global
  #dashboard-calendar
  .fc-scroller.fc-scroller-liquid-absolute::-webkit-scrollbar {
  width: 6px;
}

:global
  #dashboard-calendar
  .fc-scroller.fc-scroller-liquid-absolute::-webkit-scrollbar-button {
  height: 5px;
}

:global
  #dashboard-calendar
  .fc-scroller.fc-scroller-liquid-absolute::-webkit-scrollbar-track {
  background: transparent;
}

:global
  #dashboard-calendar
  .fc-scroller.fc-scroller-liquid-absolute::-webkit-scrollbar-thumb {
  background: var(--light-mid-grey);
  border-radius: 6px;
}

:global #dashboard-calendar .calendar-header.fc-col-header-cell {
  border: none;
  height: 32px;
}
:global #dashboard-calendar .calendar-cell {
  font-family: 'Nexa';
}
:global #dashboard-calendar .calendar-cell .fc-daygrid-day-events {
  min-height: 0 !important;
}
:global #dashboard-calendar .calendar-cell.fc-daygrid-day {
  border: none;
}

:global #dashboard-calendar .calendar-cell.fc-daygrid-day.fc-day-past div {
  color: var(--grey-01);
  pointer-events: none;
}
:global
  #dashboard-calendar
  .calendar-cell.fc-daygrid-day.fc-day-today
  :local(.dayNumber),
:global
  #dashboard-calendar
  .calendar-cell.fc-daygrid-day.fc-day-future
  :local(.dayNumber) {
  color: var(--darkest-grey);
}
:global #dashboard-calendar .calendar-cell.fc-daygrid-day.fc-day-today:hover,
:global
  #dashboard-calendar
  .calendar-cell.fc-daygrid-day:not(.fc-day-past):hover
  > div {
  opacity: 1;
  border-radius: 1rem;
  border: 1px solid var(--magenta);
}

:global
  #dashboard-calendar
  .calendar-cell.fc-daygrid-day.fc-day-today.active-cell,
:global
  #dashboard-calendar
  .calendar-cell.fc-daygrid-day.fc-day-future.active-cell {
  opacity: 1;
  background: linear-gradient(206.28deg, #b01bae 0%, #da3f85 100%);
}
:global
  #dashboard-calendar
  .calendar-cell.fc-daygrid-day.fc-day-today.active-cell
  div,
:global
  #dashboard-calendar
  .calendar-cell.fc-daygrid-day.fc-day-future.active-cell
  div,
:global
  #dashboard-calendar
  .calendar-cell.fc-daygrid-day.fc-day-today.active-cell
  span,
:global
  #dashboard-calendar
  .calendar-cell.fc-daygrid-day.fc-day-future.active-cell
  span {
  color: var(--white) !important;
}
:global #dashboard-calendar .calendar-cell.fc-daygrid-day.fc-day-today {
  background-color: var(--white);
}
:global #dashboard-calendar .calendar-cell.fc-daygrid-day.fc-day-future {
  background-color: var(--white);
}
:global
  #dashboard-calendar
  .calendar-cell.fc-daygrid-day.fc-day-future.fc-day-other
  .fc-daygrid-day-top {
  opacity: 1;
}
:global
  #dashboard-calendar
  .calendar-cell.fc-daygrid-day.fc-day-future.fc-day-other
  .fc-daygrid-day-top
  :local(.dayNumber) {
  color: var(--grey-01);
}
:global #dashboard-calendar .calendar-cell .fc-daygrid-day-frame {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  min-width: 80px;
  height: 80px;
}

:global #dashboard-calendar .calendar-cell.fc-daygrid-day.fc-day-today {
  background-color: #f7edf7;
  border-radius: 10px;
}

.wrapper {
  margin-top: 8px;
  width: 100%;
}

.wrapper table {
  border: none !important;
}

.wrapper th {
  border: none !important;
}

.wrapper td {
  border: none !important;
}
