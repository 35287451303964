.wrapper > div {
  height: 100%;
  overflow: hidden;
}

.tooltip {
  background-color: var(--lighest-grey) !important;
  border: 1px solid var(--light-grey) !important;
  border-radius: 10px !important;
  padding: 0 !important;
  width: 235px !important;
  max-height: 238px !important;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1 !important;
  margin-left: 0 !important;
  background-color: white !important;
  overflow: auto;
  box-shadow: 0px 0px 5px 2px rgba(220, 220, 220, 0.50);
}

.wrapper :global(div[data-id="tooltip"]::before) {
  visibility: hidden !important;
}

.wrapper :global(div[data-id="tooltip"]::after) {
  visibility: hidden !important;
}