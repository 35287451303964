.cellWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dayNumber {
  font-size: 1.6rem;
}

.monthNumber {
  font-size: 1.2rem;
  color: var(--grey-01);
}
.openSlot {
  font-size: 1.1rem;
  color: var(--magenta);
  text-align: center;
}

.loading {
  width: 4rem;
  height: 1rem;
  border-radius: 10rem;
  animation: loading-animation infinite 1s;
}

@keyframes loading-animation {
  from {
    background: linear-gradient(90deg, #f0e0f0 0%, rgba(250, 228, 250, 0) 100%);
  }

  to {
    background: #f0e0f0;
  }
}
