:global(.form-control).input {
  @apply shadow-primary border-lightest-grey hover:border-magenta hover:shadow-input-active focus:border-magenta focus:shadow-input-active;
  font-family: 'Nexa';
  padding: 2.6rem;
  padding-left: calc(5.45rem + 1.6rem);
  border: 0.5px solid transparent;
  border-radius: 0.8rem;
  width: 100%;
  color: #373737;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
:global(.form-control).input::placeholder {
  color: #a9a9a9;
}
:global(.form-control).input:hover,
:global(.form-control).input:focus {
  border-color: #b01bae;
  box-shadow: 0px 0px 5px 2px rgba(176, 27, 174, 0.2);
}
:global(.form-control).input.error {
  border-color: rgba(245, 34, 45, 1);
  box-shadow: unset;
}
:global(.flag-dropdown).button {
  border: none;
  background: unset !important;
}
:global .selected-flag {
  width: 5.45rem !important;
  background-color: unset !important;
  padding-left: 1.4rem !important;
}
:global .selected-flag:hover {
  background-color: unset !important;
}
:global .selected-flag .flag {
  top: 49%;
}
:global .dial-code::before {
  content: '+';
}
.container {
  font-size: 1.4rem;
  font-family: 'Nexa';
  line-height: 2.1rem;
  border-color: #c2c1c1;
  background-color: #fff f;
  border-radius: 0.4rem;
}
.error-message {
  font-size: 1.2rem;
  font-family: 'Nexa';
  line-height: 1.8rem;
  margin-right: 1.4rem;
  margin-top: 0.3rem;
  text-align: left;
  font-weight: 400;
  letter-spacing: 0.03333em;
}
.container {
  display: inline-flex;
  width: 100%;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
}
.input-wrapper {
  position: relative;
}
.divider {
  position: absolute;
  z-index: 1;
  display: flex;
  height: 80%;
  border-right: 0.5px solid #a9a9a9;
  left: 5.45rem;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
}
